import { Container, Grid } from "@mui/material";

const TabLayout = ({ children }) => {
  return (
    <Grid item>
      <Container
        sx={{
          display: "flex",
          gap: 4,
          mb: 2,
          height: "650px",
        }}
      >
        {children}
      </Container>
    </Grid>
  );
};

export default TabLayout;
