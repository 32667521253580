export const FETCH_ALL_BOTS_START = "FETCH_ALL_BOTS_START";
export const FETCH_ALL_BOTS_RECIEVES = "FETCH_ALL_BOTS_RECIEVES";
export const FETCH_ALL_BOTS_END = "FETCH_ALL_BOTS_END";

export const FETCH_SINGLE_BOT_START = "FETCH_SINGLE_BOT_START";
export const FETCH_SINGLE_BOT_RECIEVES = "FETCH_SINGLE_BOT_RECIEVES";
export const FETCH_SINGLE_BOT_END = "FETCH_SINGLE_BOT_END";

export const FETCH_ALL_BOT_THEMES_START = "FETCH_ALL_BOT_THEMES_START";
export const FETCH_ALL_BOT_THEMES_RECIEVES = "FETCH_ALL_BOT_THEMES_RECIEVES";
export const FETCH_ALL_BOT_THEMES_END = "FETCH_ALL_BOT_THEMES_END";