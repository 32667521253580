import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ChevronRight = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default ChevronRight;
