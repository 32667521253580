import Button from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";

export default function Popup(props) {
  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength - 3) + "..."
      : text;
  }
  const { botInput, botImageUrl, modeColors } = props;
  return (
    <Box
      sx={(theme) => ({
        width: "70%",
        height: "60%",
        position: "absolute",
        zIndex: "100",
        display: "flex",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "20px",
        backgroundColor: modeColors.bgPrimary,
        flexDirection: "column",
      })}
    >
      <Box
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "#0692E2",
          margin: 0,
          padding: 0,
          borderRadius: "20px 20px 0px 0px",
        }}
      />
      <Box
        sx={(theme) => ({
          backgroundColor: modeColors.bgPrimary,
          padding: 1,
          borderRadius: "50%",
          margin: "auto",
          mt: "-45px",
          width: 80,
          height: 80,
          boxShadow: modeColors.boxShadow,
        })}
        component={"img"}
        alt="Bot Icon"
        src={botImageUrl ? botImageUrl : "/static/robot.png"}
      />
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          top: "70%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            px: 1,
            textAlign: "center",
            color: modeColors.textPrimary,
            fontWeight: "bold",
            fontSize: "23px",
          }}
        >
          {botInput?.heading ? (
            truncateText(botInput?.heading, 50)
          ) : (
            <>
              Hello! I am your{" "}
              {botInput?.title
                ? truncateText(botInput?.title, 40)
                : "BOT-TITLE"}
              .
            </>
          )}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: modeColors.textPrimary,
            fontWeight: "light",
            fontSize: "14px",
            px: 2,
            pb: 1,
          }}
        >
          {botInput?.subheading
            ? truncateText(botInput?.subheading, 190)
            : "Let me assist you in your research. Click on Get Started to start exploring."}
        </Typography>
        <Button
          sx={(theme) => ({
            margin: "auto",
            minWidth: "30%",
            borderRadius: "5px",
            bgcolor: "#0692E2",
            color: "white",
            "&:hover": {
              bgcolor: "rgba(6, 146, 226, 0.9)",
              color: "white",
            },
          })}
        >
          Get Started
        </Button>

        <Divider
          sx={{
            mt: 3,
            mx: 3,
            borderColor: "#D3D3D3",
          }}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: 0.5,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "small",
              color: modeColors.textPrimary,
            }}
          >
            Powered by
          </Typography>
          <Box
            component={"img"}
            src={
              modeColors.mode === "light"
                ? "/static/Apex_Logo_2022_large.png"
                : "/static/Apex_Logo_2022Medium.png"
            }
            width={"35px"}
            height={"10px"}
            marginTop={0.4}
          />
        </Box>
      </Box>
    </Box>
  );
}
