import React from "react";
import { Navigate } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import LoginForm from "../../components/auth/forms/LoginForm";

import useAuth from "src/hooks/useAuth";

const Login: React.FC<any> = (): any => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box style={{ position: "absolute", left: 40, top: 40 }}>
        <img src="/static/Apex_Logo_2022Medium.png" alt="logo" height="40px" />
      </Box>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              minHeight: "100vh",
              background:
                "#000 URL(/assets/images/auth-bg.png) center center no-repeat",
              backgroundSize: "cover",
              px: 10,
              py: 5,
            }}
            style={{
              display: "flex",
              gap: 20,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" sx={(theme) => ({ color: "white" })}>
              APEX:E3 Vector Database
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={(theme) => ({ color: "white" })}
            >
              Efficient data handling, Customisable data processing, Direct data
              review capabilities, Pre-integration testing, and easy API
              accessibility
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              minHeight: "100vh",
              px: 10,
            }}
          >
            <LoginForm />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
