import axios from "axios";
import { toast } from "react-toastify";

import { getAccessToken } from "src/utils/token";
import { vectorDBConfig } from "src/config";
class CreditsQuotaApi {
  async fetchCreditsQuota() {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(
        `${baseUrl}/subscriptions/customers/credit-quota`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchQuotaHistory(body) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/quota/history`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.error("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchQuotaConsumption(claimId: string) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(
        `${baseUrl}/quota/consumptions/${claimId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const creditsQuotaApi = new CreditsQuotaApi();
