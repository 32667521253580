import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_VECTOR_RESOURCES_START,
  FETCH_VECTOR_RESOURCES_RECIEVES,
  FETCH_VECTOR_RESOURCES_END,
  FETCH_VECTOR_RESOURCE_START,
  FETCH_VECTOR_RESOURCE_RECIEVES,
  FETCH_VECTOR_RESOURCE_END,
  FETCH_CHUNK_RESOURCE_START,
  FETCH_CHUNK_RESOURCE_RECIEVES,
  FETCH_CHUNK_RESOURCE_END,
  CLEAR_CHUNK_RESOURCES,
  GENERATE_EMBEDDINGS_FOR_RESOURCE_START,
  GENERATE_EMBEDDINGS_FOR_RESOURCE_RECIEVES,
  GENERATE_EMBEDDINGS_FOR_RESOURCE_END,
  CLEAR_EMBEDDINGS,
} from "./action-types";

export const VectorResourceReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_VECTOR_RESOURCES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_VECTOR_RESOURCES_RECIEVES: {
      return {
        loading: false,
        data: data
          .filter((resource: any) => resource?.status !== "DELETED")
          .sort((a: any, b: any) => b.createdAt - a.createdAt),
        error: "",
      };
    }
    case FETCH_VECTOR_RESOURCES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const SingleVectorResourceReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_VECTOR_RESOURCE_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_VECTOR_RESOURCE_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_VECTOR_RESOURCE_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const ChunkTextResourceReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: [],
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_CHUNK_RESOURCE_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_CHUNK_RESOURCE_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_CHUNK_RESOURCE_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    case CLEAR_CHUNK_RESOURCES: {
      return {
        loading: false,
        data: [],
        error: "",
      };
    }
    default:
      return state;
  }
};

export const GenerateEmbeddingsForResourceReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: [],
  },
  { type, data, error }
) => {
  switch (type) {
    case GENERATE_EMBEDDINGS_FOR_RESOURCE_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case GENERATE_EMBEDDINGS_FOR_RESOURCE_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case GENERATE_EMBEDDINGS_FOR_RESOURCE_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    case CLEAR_EMBEDDINGS: {
      return {
        loading: false,
        data: [],
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  VectorResourceReducer,
  SingleVectorResourceReducer,
  ChunkTextResourceReducer,
  GenerateEmbeddingsForResourceReducer,
});
