export const FETCH_CREDITS_QUOTA_START = "FETCH_CREDITS_QUOTA_START";
export const FETCH_CREDITS_QUOTA_RECIEVES = "FETCH_CREDITS_QUOTA_RECIEVES";
export const FETCH_CREDITS_QUOTA_END = "FETCH_CREDITS_QUOTA_END";

export const FETCH_QUOTA_HISTORY_START = "FETCH_QUOTA_HISTORY_START";
export const FETCH_QUOTA_HISTORY_RECIEVES = "FETCH_QUOTA_HISTORY_RECIEVES";
export const FETCH_QUOTA_HISTORY_END = "FETCH_QUOTA_HISTORY_END";

export const FETCH_QUOTA_CONSUMPTION_START = "FETCH_QUOTA_CONSUMPTION_START";
export const FETCH_QUOTA_CONSUMPTION_RECIEVES = "FETCH_QUOTA_CONSUMPTION_RECIEVES";
export const FETCH_QUOTA_CONSUMPTION_END = "FETCH_QUOTA_CONSUMPTION_END";

