import { FC, useState } from "react";

import * as Yup from "yup";
import { Formik } from "formik";

import TermsAndConditions from "./TermsAndConditions";

import useAuth from "src/hooks/useAuth";
import useMounted from "src/hooks/useMounted";

import { ssoConfig } from "src/config";

import linkedin from "src/assets/linkedin.svg";
import google from "src/assets/google.svg";

import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";

const RegisterJWT: FC = (props) => {
  const mounted = useMounted();
  const { register, loginSSO } = useAuth() as any;
  const {
    googleClientId,
    linkedinClientId,
    linkedinClientSecret,
    redirectUri,
  } = ssoConfig();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          policy: false,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          firstName: Yup.string().max(255).required("First name is required"),
          lastName: Yup.string().max(255).required("Last Name is required"),
          password: Yup.string()
            .min(7)
            .max(255)
            .required("Password is required"),
          policy: Yup.boolean().oneOf([true], "This field must be checked"),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            await register(
              values.email,
              values.firstName,
              values.lastName,
              values.password
            );

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Box style={{ display: "flex", gap: 15, width: "100%" }}>
              <LoginSocialGoogle
                client_id={googleClientId}
                fetch_basic_profile={true}
                typeResponse="accessToken"
                scope={
                  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                }
                onResolve={async ({ provider, data }: IResolveParams) => {
                  await loginSSO(data, provider);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <img
                  style={{ width: "60px", cursor: "pointer" }}
                  alt="googlLogo"
                  src={google}
                />
              </LoginSocialGoogle>

              <LoginSocialLinkedin
                client_id={linkedinClientId}
                client_secret={linkedinClientSecret}
                scope={"r_emailaddress+r_liteprofile"}
                redirect_uri={`${redirectUri}/dashboard`}
                onResolve={async ({ provider, data }: IResolveParams) => {
                  await loginSSO(data, provider);
                }}
                onReject={(err: any) => {
                  console.log(err);
                }}
              >
                <img
                  style={{ width: "60px", cursor: "pointer" }}
                  alt="linkedInLogo"
                  src={linkedin}
                />
              </LoginSocialLinkedin>
            </Box>

            <Divider sx={{ my: 3 }} textAlign="center">
              OR
            </Divider>

            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={
                touched.firstName && errors.firstName
                  ? touched.firstName && errors.firstName
                  : ` `
              }
              label="First Name"
              margin="normal"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              variant="outlined"
              style={{ margin: "0" }}
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={
                touched.lastName && errors.lastName
                  ? touched.lastName && errors.lastName
                  : ` `
              }
              label="Last Name"
              margin="normal"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              variant="outlined"
              style={{ margin: "0" }}
            />
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={
                touched.email && errors.email
                  ? touched.email && errors.email
                  : ` `
              }
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
              style={{ margin: "0" }}
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={
                touched.password && errors.password
                  ? touched.password && errors.password
                  : ` `
              }
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
              style={{ margin: "0" }}
            />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
                mt: 2,
              }}
            >
              <Checkbox
                checked={values.policy}
                color="primary"
                name="policy"
                onChange={handleChange}
              />
              <Typography color="textSecondary" variant="body2">
                I have read the <>&nbsp;</>
              </Typography>
              <Typography
                color="primary"
                onClick={() => setIsModalOpen(true)}
                sx={{ cursor: "pointer" }}
              >
                Terms and Conditions
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) ? (
              <FormHelperText error>{errors.policy}</FormHelperText>
            ) : (
              <>&nbsp;</>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Register
              </Button>
            </Box>
            {Boolean(errors.submit && errors.submit) ? (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{`${errors.submit}`}</FormHelperText>
              </Box>
            ) : (
              <p>&nbsp;</p>
            )}
          </form>
        )}
      </Formik>
      {isModalOpen && (
        <TermsAndConditions
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default RegisterJWT;
