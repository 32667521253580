import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
} from "@mui/material";

interface DialogLayoutProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action?: string;
  handleAction?: () => void;
  isDeleteDialog?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
  width?: string;
}

const DialogLayout: React.FC<DialogLayoutProps> = ({
  isOpen,
  onClose,
  title,
  action,
  handleAction,
  isDeleteDialog,
  isLoading,
  children,
  width,
}: DialogLayoutProps) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      <Card
        sx={(theme) => ({
          width: width || "650px",
          overflow: "auto",
          backgroundColor: theme.palette.background.default,
          border: "none",
          borderRadius: "10px",
          p: 2.5,
        })}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "19px" }}>
            {title}
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {children}
        <Divider sx={{ mb: 1, mt: 2 }} />
        <CardActions
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={onClose}
            sx={{ borderRadius: "10px" }}
          >
            Cancel
          </Button>
          {action && (
            <Button
              color={isDeleteDialog ? "secondary" : "primary"}
              variant="contained"
              onClick={handleAction}
              sx={{ borderRadius: "10px", width: "64px" }}
              disabled={isLoading}
            >
              {!isLoading ? (
                action
              ) : (
                <Box sx={{ color: "white" }}>
                  <CircularProgress value={100} size="15px" color="inherit" />
                </Box>
              )}
            </Button>
          )}
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default DialogLayout;
