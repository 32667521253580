import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import Bot from './pages/Bot';
import BotForm from './pages/dashboard/BotForm';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/Auth/Login')));
const Register = Loadable(lazy(() => import('./pages/Auth/Register')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/Auth/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/Auth/PasswordReset')));
const PasswordResetByToken = Loadable(lazy(() => import('./pages/Auth/PasswordResetByToken')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages
const ClientSecrets = Loadable(lazy(() => import('./pages/dashboard/ClientSecrets')));
const VectorIndexes = Loadable(lazy(() => import('./pages/dashboard/VectorIndexes')));
const VectorResources = Loadable(lazy(() => import('./pages/dashboard/VectorResources')));
const QuotaHistory = Loadable(lazy(() => import('./pages/dashboard/QuotaHistory')));
const StripeCheckout = Loadable(lazy(() => import('./pages/dashboard/StripeCheckout')));
const PaymentHistory = Loadable(lazy(() => import('./pages/dashboard/PaymentHistory')));
const PricingPlans = Loadable(lazy(() => import('./pages/dashboard/PricingPlans')));
const Bots = Loadable(lazy(() => import('./pages/dashboard/Bots')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'password-reset-token',
        element: <PasswordResetByToken />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <VectorIndexes />
      },
      {
        path: 'vector-resources',
        element: <VectorResources />
      },
      {
        path: 'clients',
        element: <ClientSecrets />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'pricing-plans',
        element: <PricingPlans />
      },
      {
        path: 'checkout',
        element: <StripeCheckout />
      },
      {
        path: 'payment-history',
        element: <PaymentHistory />
      },
      {
        path: 'quota-history',
        element: <QuotaHistory />
      },
      {
        path: 'bots',
        element: <Bots />
      },
      {
        path: 'bot-form',
        element: <BotForm />
      }
    ]
  },
  {
    path: 'bot',
    element: (
      <AuthGuard>
        <Bot />
      </AuthGuard>
      

    )
  },
  {
    path: 'admin',
    element: (
      <AuthGuard admin>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: []
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: <Login />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
