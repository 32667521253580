import React, { useEffect } from "react";

import { Box, Container, Typography } from "@mui/material";

import PasswordResetByTokenForm from "src/components/auth/forms/PasswordResetByTokenForm";

import gtm from "src/lib/gtm";

const PasswordReset: React.FC<any> = (): any => {
  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="textPrimary" gutterBottom variant="h4">
              Change Your Password
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 1,
          }}
        >
          <PasswordResetByTokenForm />
        </Box>
      </Container>
    </Box>
  );
};
export default PasswordReset;
