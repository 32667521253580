import { isString } from "lodash";

import { vectorIndexesApi } from "src/__Api__/vectorIndexesApi";

import {
  FETCH_VECTOR_INDEXES_START,
  FETCH_VECTOR_INDEXES_RECIEVES,
  FETCH_VECTOR_INDEXES_END,
  FETCH_VECTOR_INDEX_START,
  FETCH_VECTOR_INDEX_RECIEVES,
  FETCH_VECTOR_INDEX_END,
  FETCH_MODEL_DIMENSIONS_START,
  FETCH_MODEL_DIMENSIONS_RECIEVES,
  FETCH_MODEL_DIMENSIONS_END,
  SEARCH_INDEX_START,
  SEARCH_INDEX_RECIEVES,
  SEARCH_INDEX_END,
  CLEAR_SEARCHES,
} from "./action-types";

import { VectorIndexes, fetchVectorIndexes } from "src/types/vectorIndexes";

export const fetchAllVectorIndexes =
  (body: fetchVectorIndexes, setIndexLoading: any) => async (dispatch) => {
    setIndexLoading(true);
    try {
      dispatch({ type: FETCH_VECTOR_INDEXES_START });

      const result = await vectorIndexesApi.fetchAllVectorIndexes(body);

      if (isString(result)) {
        setIndexLoading(false);
        dispatch({
          type: FETCH_VECTOR_INDEXES_END,
          data: [],
          error: result,
        });
      } else {
        setIndexLoading(false);
        dispatch({
          type: FETCH_VECTOR_INDEXES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      setIndexLoading(false);
      dispatch({
        type: FETCH_VECTOR_INDEXES_END,
        data: [],
        error,
      });
    }
  };

export const fetchVectorIndex =
  (id: string, setIsLoading: any) => async (dispatch) => {
    setIsLoading(true);
    try {
      dispatch({ type: FETCH_VECTOR_INDEX_START });

      const result = await vectorIndexesApi.fetchVectorIndex(id);

      if (isString(result)) {
        setIsLoading(false);
        dispatch({
          type: FETCH_VECTOR_INDEX_END,
          data: [],
          error: result,
        });
      } else {
        setIsLoading(false);
        dispatch({
          type: FETCH_VECTOR_INDEX_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: FETCH_VECTOR_INDEX_END,
        data: [],
        error,
      });
    }
  };

export const fetchAllModelDimensions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MODEL_DIMENSIONS_START });

    const result = await vectorIndexesApi.fetchAllModelDimensions();

    if (isString(result)) {
      dispatch({
        type: FETCH_MODEL_DIMENSIONS_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_MODEL_DIMENSIONS_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_MODEL_DIMENSIONS_END,
      data: [],
      error,
    });
  }
};

export const createVectorIndexe =
  (
    body: VectorIndexes,
    setIsLoading: any,
    handleModal: any,
    setIndexLoading: any
  ) =>
  async (dispatch) => {
    setIsLoading(true);
    try {
      const result = await vectorIndexesApi.createVectorIndexes(body);
      if (isString(result)) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        const body = {
          currentPage: 0,
          perPage: 10,
          sortBy: "asc",
        };
        dispatch(fetchAllVectorIndexes(body, setIndexLoading));
        handleModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

export const deleteVectorIndexe =
  (
    id: string,
    setIsLoading: any,
    setIsDeleteModal: any,
    setIndexLoading: any
  ) =>
  async (dispatch) => {
    setIsLoading(true);
    try {
      const result = await vectorIndexesApi.deleteVectorIndexe(id);
      if (result === "done") {
        setIsLoading(false);
        const body = {
          currentPage: 0,
          perPage: 10,
          sortBy: "asc",
        };
        dispatch(fetchAllVectorIndexes(body, setIndexLoading));
        setIsDeleteModal(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

export const searchIndex =
  (body: any, setIsLoading: any) => async (dispatch) => {
    setIsLoading(true);
    try {
      dispatch({ type: SEARCH_INDEX_START });
      const result = await vectorIndexesApi.searchIndex(body);
      if (isString(result)) {
        setIsLoading(false);
        dispatch({
          type: SEARCH_INDEX_END,
          data: [],
          error: result,
        });
      } else {
        setIsLoading(false);
        dispatch({
          type: SEARCH_INDEX_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: SEARCH_INDEX_END,
        data: [],
        error,
      });
    }
  };

export const clearSearches = () => ({
  type: CLEAR_SEARCHES,
});
