import React from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  Link,
  Container,
  Typography,
  Breadcrumbs,
  Box,
  Tabs,
  Tab,
} from "@mui/material";

import BotWidget from "src/components/dashboard/botWidget/BotWidget";
import Popup from "src/components/dashboard/botWidget/Popup";
import TabLayout from "src/components/dashboard/botForm/TabLayout";
import WidgetLayout from "src/components/dashboard/botForm/WidgetLayout";
import FormLayout from "src/components/dashboard/botForm/FormLayout";
import BasicInfoForm from "src/components/dashboard/botForm/BasicInfoForm";
import CustomTextForm from "src/components/dashboard/botForm/CustomTextForm";
import MediaForm from "src/components/dashboard/botForm/MediaForm";
import ChatScreen from "src/components/dashboard/botWidget/ChatScreen";

import useBotForm from "src/hooks/useBotForm";

import ChevronRightIcon from "src/icons/ChevronRight";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%", height: "100%" }}>
          <Typography sx={{ width: "100%", height: "100%" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BotForm: React.FC<any> = () => {
  const {
    links,
    value,
    logoUrl,
    botInput,
    urlInput,
    linkInput,
    modeColors,
    allowedUrls,
    botImageUrl,
    handleChange,
    isUpdateForm,
    updatedLinks,
    submitClicked,
    loadingStates,
    handleAddLink,
    updateBotInput,
    handleBotInput,
    addLinkClicked,
    handleUpdateBot,
    logoUrlToUpdate,
    handleLinkInput,
    handleRemoveUrl,
    handleCreateBot,
    botThemeOptions,
    handleUrlKeyDown,
    handleRemoveLink,
    handleLogoUpload,
    setSubmitClicked,
    allowedUrlsUpdate,
    vectorIndexOptions,
    botImageUrlToUpdate,
    handleUpdateBotInput,
    handleUrlInputChange,
    handleBotImageUpload,
  } = useBotForm();

  return (
    <>
      <Helmet>
        <title>
          APEXE3 Vector Database -{" "}
          {!isUpdateForm ? "Create Agent" : "Update Agent"}
        </title>
      </Helmet>
      <Container>
        <Grid position="relative" sx={{ py: 4 }}>
          <Grid container spacing={2} sx={{ width: "calc(100% + 70px)" }}>
            <Grid item xs={12} md={8}>
              <Grid item sx={{ paddingLeft: 2 }}>
                <Typography color="textPrimary" variant="h5">
                  {!isUpdateForm ? "Create" : "Update"} Agent Form
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRightIcon fontSize="small" />}
                    sx={{ mt: 1 }}
                  >
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="/dashboard"
                      variant="subtitle2"
                    >
                      Overview
                    </Link>
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="/dashboard/bots"
                      variant="subtitle2"
                    >
                      Agents
                    </Link>
                    <Typography color="textPrimary" variant="subtitle2">
                      {!isUpdateForm ? "Create" : "Update"} Agent
                    </Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Container
              sx={{
                display: "flex",
                gap: 4,
                mt: 1,
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{ border: "none" }}
                >
                  <Tab label="Popup" {...a11yProps(0)} />
                  <Tab label="Main Screen" {...a11yProps(1)} />
                  <Tab label="Media" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </Container>
          </Grid>

          <CustomTabPanel value={value} index={0}>
            <TabLayout>
              <WidgetLayout>
                <Container
                  sx={(theme) => ({
                    height: "100%",
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                    border: `1px solid ${theme.palette.background.default}`,
                    borderRadius: "20px",
                    position: "relative",
                  })}
                >
                  <Popup
                    botInput={!isUpdateForm ? botInput : updateBotInput}
                    botImageUrl={
                      !isUpdateForm ? botImageUrl : botImageUrlToUpdate
                    }
                    modeColors={
                      !isUpdateForm
                        ? botInput.mode === "light"
                          ? modeColors.lightModeColors
                          : modeColors.darkModeColors
                        : updateBotInput.mode === "light"
                        ? modeColors.lightModeColors
                        : modeColors.darkModeColors
                    }
                  />
                </Container>
              </WidgetLayout>
              <FormLayout
                isDisabled={
                  !isUpdateForm
                    ? loadingStates.createBot
                    : loadingStates.updateBot
                }
                onClick={() => {
                  setSubmitClicked(true);
                  if (!isUpdateForm) {
                    if (
                      botInput.title &&
                      botInput.vectorIndex &&
                      botInput.botThemeId
                    ) {
                      handleCreateBot();
                      setSubmitClicked(false);
                    }
                  } else {
                    if (
                      updateBotInput.title &&
                      updateBotInput.vectorIndex &&
                      updateBotInput.botThemeId
                    ) {
                      handleUpdateBot();
                      setSubmitClicked(false);
                    }
                  }
                }}
                isUpdateForm={isUpdateForm}
                loadingStates={{
                  createBot: loadingStates.createBot,
                  updateBot: loadingStates.updateBot,
                }}
              >
                <BasicInfoForm
                  input={!isUpdateForm ? botInput : updateBotInput}
                  handleInput={
                    !isUpdateForm ? handleBotInput : handleUpdateBotInput
                  }
                  submitClicked={submitClicked}
                  vectorIndexOptions={vectorIndexOptions}
                  botThemeOptions={botThemeOptions}
                  urlInput={urlInput}
                  handleUrlInputChange={handleUrlInputChange}
                  handleUrlKeyDown={handleUrlKeyDown}
                  allowedUrls={!isUpdateForm ? allowedUrls : allowedUrlsUpdate}
                  handleRemoveUrl={handleRemoveUrl}
                  linkInput={linkInput}
                  handleLinkInput={handleLinkInput}
                  addLinkClicked={addLinkClicked}
                  handleAddLink={handleAddLink}
                  links={!isUpdateForm ? links : updatedLinks}
                  handleRemoveLink={handleRemoveLink}
                />
              </FormLayout>
            </TabLayout>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <TabLayout>
              <WidgetLayout>
                <BotWidget
                  botInput={!isUpdateForm ? botInput : updateBotInput}
                  botImageUrl={
                    !isUpdateForm ? botImageUrl : botImageUrlToUpdate
                  }
                  logoUrl={!isUpdateForm ? logoUrl : logoUrlToUpdate}
                  modeColors={
                    !isUpdateForm
                      ? botInput.mode === "light"
                        ? modeColors.lightModeColors
                        : modeColors.darkModeColors
                      : updateBotInput.mode === "light"
                      ? modeColors.lightModeColors
                      : modeColors.darkModeColors
                  }
                />
              </WidgetLayout>
              <FormLayout
                isDisabled={
                  !isUpdateForm
                    ? loadingStates.createBot
                    : loadingStates.updateBot
                }
                onClick={() => {
                  setSubmitClicked(true);
                  if (!isUpdateForm) {
                    if (
                      botInput.title &&
                      botInput.vectorIndex &&
                      botInput.botThemeId
                    ) {
                      handleCreateBot();
                      setSubmitClicked(false);
                    }
                  } else {
                    if (
                      updateBotInput.title &&
                      updateBotInput.vectorIndex &&
                      updateBotInput.botThemeId
                    ) {
                      handleUpdateBot();
                      setSubmitClicked(false);
                    }
                  }
                }}
                isUpdateForm={isUpdateForm}
                loadingStates={{
                  createBot: loadingStates.createBot,
                  updateBot: loadingStates.updateBot,
                }}
              >
                <CustomTextForm
                  input={!isUpdateForm ? botInput : updateBotInput}
                  handleInput={
                    !isUpdateForm ? handleBotInput : handleUpdateBotInput
                  }
                />
              </FormLayout>
            </TabLayout>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <TabLayout>
              <WidgetLayout>
                <ChatScreen
                  botInput={!isUpdateForm ? botInput : updateBotInput}
                  botImageUrl={
                    !isUpdateForm ? botImageUrl : botImageUrlToUpdate
                  }
                  logoUrl={!isUpdateForm ? logoUrl : logoUrlToUpdate}
                  modeColors={
                    !isUpdateForm
                      ? botInput.mode === "light"
                        ? modeColors.lightModeColors
                        : modeColors.darkModeColors
                      : updateBotInput.mode === "light"
                      ? modeColors.lightModeColors
                      : modeColors.darkModeColors
                  }
                />
              </WidgetLayout>
              <FormLayout
                isDisabled={
                  !isUpdateForm
                    ? loadingStates.createBot
                    : loadingStates.updateBot
                }
                onClick={() => {
                  setSubmitClicked(true);
                  if (!isUpdateForm) {
                    if (
                      botInput.title &&
                      botInput.vectorIndex &&
                      botInput.botThemeId
                    ) {
                      handleCreateBot();
                      setSubmitClicked(false);
                    }
                  } else {
                    if (
                      updateBotInput.title &&
                      updateBotInput.vectorIndex &&
                      updateBotInput.botThemeId
                    ) {
                      handleUpdateBot();
                      setSubmitClicked(false);
                    }
                  }
                }}
                isUpdateForm={isUpdateForm}
                loadingStates={{
                  createBot: loadingStates.createBot,
                  updateBot: loadingStates.updateBot,
                }}
              >
                <MediaForm
                  loadingStates={loadingStates}
                  botImageUrl={isUpdateForm ? botImageUrlToUpdate : botImageUrl}
                  handleBotImageUpload={handleBotImageUpload}
                  isUpdateForm={isUpdateForm}
                  logoUrl={isUpdateForm ? logoUrlToUpdate : logoUrl}
                  handleLogoUpload={handleLogoUpload}
                  input={isUpdateForm ? updateBotInput : botInput}
                  handleInput={
                    isUpdateForm ? handleUpdateBotInput : handleBotInput
                  }
                />
              </FormLayout>
            </TabLayout>
          </CustomTabPanel>
        </Grid>
      </Container>
    </>
  );
};

export default BotForm;
