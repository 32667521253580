import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { fetchCredits } from "src/store/creditsQuota/actions";
import { fetchAllVectorIndexes } from "src/store/vectorIndexes/actions";
import {
  createBot,
  fetchAllBotThemes,
  fetchBot,
  updateBot,
  uploadImageFile,
} from "src/store/bots/actions";

import { CreateBot } from "src/types/bots";

const useBotForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  const { data: botDetails = [] } = useSelector(
    (state: any) => state?.bots?.BotReducer
  );
  const { data: botThemes = [] } = useSelector(
    (state: any) => state?.bots?.BotThemesReducer
  );
  const { data: vectorIndexes = [] } = useSelector(
    (state: any) => state?.vectorIndexes?.VectorIndexeReducer
  );

  const vectorIndexOptions =
    vectorIndexes?.data?.map((index: any) => ({
      label: index.name,
      id: index.id,
    })) || [];

  const botThemeOptions =
    (Array.isArray(botThemes) &&
      botThemes?.map((theme: any) => ({
        label: theme.title,
        id: theme.id,
      }))) ||
    [];

  const botInitialState = {
    title: "",
    vectorIndex: "",
    botThemeId: "",
    heading: "Hello! I am your Agent.",
    subheading:
      "Let me assist you in your research. Click on Get Started to start exploring.",
    welcomeHeading: "Welcome!",
    welcomeSubheading: "How can I assist you today?",
    mode: "light",
  };

  const modeColors = {
    darkModeColors: {
      mode: "dark",
      bgPrimary: "#19191e",
      bgSecondary: "#29292e",
      textPrimary: "#fff",
      boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.1)",
      border: "#fff",
      messageBgColor: "#0692e2",
      messageTextColor: "white",
    },
    lightModeColors: {
      mode: "light",
      bgPrimary: "#fff",
      bgSecondary: "#e1e1e3",
      textPrimary: "#29292e",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      border: "rgba(0, 0, 0, 0.2)",
      messageBgColor: "rgba(6, 146, 226, 0.6)",
      messageTextColor: "black",
    },
  };

  const [linkInput, setLinkInput] = useState({
    title: "",
    value: "",
  });
  const [value, setValue] = useState(0);
  const [links, setLinks] = useState([]);
  const [urlInput, setUrlInput] = useState("");
  const [allowedUrls, setAllowedUrls] = useState([]);
  const [updatedLinks, setUpdatedLinks] = useState([]);
  const [botImageUrl, setBotImageUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [botImageUrlToUpdate, setBotImageUrlToUpdate] = useState(null);
  const [logoUrlToUpdate, setLogoUrlToUpdate] = useState(null);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [indexLoading, setIndexLoading] = useState(false);
  const [botInput, setBotInput] = useState(botInitialState);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [selectedBot, setSelectedBot] = useState(botDetails);
  const [addLinkClicked, setAddLinkClicked] = useState(false);
  const [allowedUrlsUpdate, setAllowedUrlsUpdate] = useState([]);
  const [updateBotInput, setUpdateBotInput] = useState(botInitialState);
  const [loadingStates, setLoadingStates] = useState({
    fetchAllBots: false,
    createBot: false,
    updateBot: false,
    fetchBot: false,
    uploadBotImage: false,
    uploadLogo: false,
  });

  const handleBotInput = (key: string, value: any) => {
    setBotInput({
      ...botInput,
      [key]: value,
    });
  };

  const handleUpdateBotInput = (key: string, value: any) => {
    setUpdateBotInput({
      ...updateBotInput,
      [key]: value,
    });
  };

  const handleLoadingStates = (key: string, value: boolean) => {
    setLoadingStates({
      ...loadingStates,
      [key]: value,
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSubmitClicked(false);
    setValue(newValue);
  };

  const handleRemoveLink = (title) => {
    if (!isUpdateForm) {
      setLinks(links.filter((link) => link.title !== title));
    } else {
      setUpdatedLinks(updatedLinks.filter((link) => link.title !== title));
    }
  };

  const handleLinkInput = (key: string, value: string) => {
    setLinkInput({
      ...linkInput,
      [key]: value,
    });
  };

  const handleAddLink = () => {
    setAddLinkClicked(true);
    if (!isUpdateForm) {
      if (linkInput.title && linkInput.value) {
        if (linkInput && !links.includes(linkInput)) {
          setLinks([...links, linkInput]);
          setLinkInput({
            title: "",
            value: "",
          });
          setAddLinkClicked(false);
        }
      }
    } else {
      if (linkInput.title && linkInput.value) {
        if (linkInput && !updatedLinks?.includes(linkInput)) {
          setUpdatedLinks([...updatedLinks, linkInput]);
          setLinkInput({
            title: "",
            value: "",
          });
          setAddLinkClicked(false);
        }
      }
    }
  };

  const handleAddUrl = () => {
    if (!isUpdateForm) {
      if (urlInput && !allowedUrls.includes(urlInput)) {
        setAllowedUrls([...allowedUrls, urlInput]);
        setUrlInput("");
      }
    } else {
      if (urlInput && !allowedUrlsUpdate.includes(urlInput)) {
        setAllowedUrlsUpdate([...allowedUrlsUpdate, urlInput]);
        setUrlInput("");
      }
    }
  };

  const handleRemoveUrl = (url: string) => {
    if (!isUpdateForm) {
      setAllowedUrls(allowedUrls.filter((item) => item !== url));
    } else {
      setAllowedUrlsUpdate(allowedUrlsUpdate.filter((item) => item !== url));
    }
  };

  const handleUrlInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrlInput(e.target.value);
  };

  const handleUrlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddUrl();
      e.preventDefault();
    }
  };

  const handleBotImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    isUpdateForm
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const url = await dispatch(
        uploadImageFile(formData, handleLoadingStates, "uploadBotImage")
      );
      if (!isUpdateForm) setBotImageUrl(url);
      else setBotImageUrlToUpdate(url);
    }
  };

  const handleLogoUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    isUpdateForm
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const url = await dispatch(
        uploadImageFile(formData, handleLoadingStates, "uploadLogo")
      );
      if (!isUpdateForm) setLogoUrl(url);
      else setLogoUrlToUpdate(url);
    }
  };

  const handleCreateBot = async () => {
    if (links.length > 3) {
      return toast.error("Maximum of 3 links allowed");
    }
    const filteredBotInput = Object.keys(botInput).reduce((acc, key) => {
      if (botInput[key] !== "") {
        acc[key] = botInput[key];
      }
      return acc;
    }, {} as CreateBot);

    const body = {
      ...filteredBotInput,
      ...(allowedUrls.length > 0
        ? { allowedUrls }
        : urlInput && { allowedUrls: [urlInput] }),
      ...(links.length > 0 && { links }),
      ...(botImageUrl && { botImage: botImageUrl }),
      ...(logoUrl && { logo: logoUrl }),
    };

    const fetchBody = {
      currentPage: 0,
      perPage: 10,
    };
    await dispatch(createBot(handleLoadingStates, body, fetchBody));
    await dispatch(fetchCredits());
    setBotInput(botInitialState);
    setAllowedUrls([]);
    setLinks([]);
    navigate("/dashboard/bots");
  };

  const handleUpdateBot = async () => {
    if (updatedLinks?.length > 3) {
      return toast.error("Maximum of 3 links allowed");
    }
    const body = {
      ...updateBotInput,
      ...(allowedUrlsUpdate.length > 0
        ? urlInput
          ? { allowedUrls: [...allowedUrlsUpdate, urlInput] }
          : { allowedUrls: allowedUrlsUpdate }
        : { allowedUrls: urlInput ? [urlInput] : allowedUrlsUpdate }),
      ...(updatedLinks.length > 0
        ? { links: updatedLinks }
        : { links: updatedLinks }),
      ...(botDetails?.botImage !== botImageUrlToUpdate &&
        botImageUrlToUpdate && { botImage: botImageUrlToUpdate }),
      ...(botDetails?.logo !== logoUrlToUpdate &&
        logoUrlToUpdate && { logo: logoUrlToUpdate }),
    };
    const fetchBody = {
      currentPage: 0,
      perPage: 10,
    };
    await dispatch(
      updateBot(selectedBot.id, body, handleLoadingStates, fetchBody)
    );
    setBotImageUrlToUpdate(null);
    setLogoUrlToUpdate(null);
    navigate("/dashboard/bots");
  };

  useEffect(() => {
    let id = queryParams.get("id");
    if (id) {
      setIsUpdateForm(true);
      dispatch(fetchBot(id, handleLoadingStates));
    }
    dispatch(fetchAllBotThemes(handleLoadingStates));
    const fetchIndexBody = {
      sortBy: "asc",
    };
    dispatch(fetchAllVectorIndexes(fetchIndexBody, setIndexLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setSelectedBot(botDetails);
    setAllowedUrlsUpdate(botDetails.allowedUrls || []);
    setUpdatedLinks(botDetails.links || []);
    setBotImageUrlToUpdate(botDetails?.botImage || null);
    setLogoUrlToUpdate(botDetails?.logo || null);

    if (botDetails?.data?.length !== 0) {
      setUpdateBotInput({
        title: botDetails?.title,
        vectorIndex: botDetails?.vectorIndexId,
        botThemeId: botDetails?.botTheme?.id,
        heading: botDetails?.heading ? botDetails?.heading : "",
        subheading: botDetails?.subheading ? botDetails?.subheading : "",
        welcomeHeading: botDetails?.welcomeHeading
          ? botDetails?.welcomeHeading
          : "",
        welcomeSubheading: botDetails?.welcomeSubheading
          ? botDetails?.welcomeSubheading
          : "",
        mode: botDetails?.mode,
      });
    }
  }, [botDetails]);

  return {
    links,
    value,
    logoUrl,
    botInput,
    urlInput,
    linkInput,
    modeColors,
    allowedUrls,
    botImageUrl,
    handleChange,
    isUpdateForm,
    updatedLinks,
    indexLoading,
    submitClicked,
    loadingStates,
    handleAddLink,
    updateBotInput,
    handleBotInput,
    addLinkClicked,
    handleUpdateBot,
    logoUrlToUpdate,
    handleLinkInput,
    handleRemoveUrl,
    handleCreateBot,
    botThemeOptions,
    handleUrlKeyDown,
    handleRemoveLink,
    handleLogoUpload,
    setSubmitClicked,
    allowedUrlsUpdate,
    vectorIndexOptions,
    botImageUrlToUpdate,
    handleUpdateBotInput,
    handleUrlInputChange,
    handleBotImageUpload,
  };
};

export default useBotForm;
