import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";

import axios from "axios";
import PropTypes from "prop-types";

import { useDispatch } from "src/store";

import { authApi } from "../__Api__/authApi";
import { subscriptionApi } from "src/__Api__/subscriptionApi";
import { creditsQuotaApi } from "src/__Api__/creditsQuotaApi";

import wait from "src/utils/wait";
import type { User } from "../types/user";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: Error;
}

interface AuthContextValue extends State {
  platform: "JWT";
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  logoutSSO: () => Promise<void>;
  register: (
    email: string,
    firstname: string,
    lastname: string,
    password: string
  ) => Promise<void>;
  loginSSO: (data: any, provider: string, setIsLoading: any) => Promise<void>;
  passwordRecovery: (email: string) => Promise<void>;
  passwordReset: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  refreshToken: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    user: User;
  };
};

type PasswordRecoveryAction = {
  type: "PASSWORD_RECOVERY";
};

type LoginFailedAction = {
  type: "LOGIN_FAILED";
  payload: {
    error: any;
  };
};

type PasswordResetAction = {
  type: "PASSWORD_RESET";
};

type RefreshTokenAction = {
  type: "REFRESH_TOKEN";
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | PasswordRecoveryAction
  | LoginFailedAction
  | PasswordResetAction
  | RefreshTokenAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    state.user = user;
    return {
      ...state,
      isAuthenticated: true,
      user,
      error: null,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  LOGIN_FAILED: (state: State, action: LoginFailedAction): State => ({
    ...state,
    error: action.payload.error,
  }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutSSO: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginSSO: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const sso = window.localStorage.getItem("sso");
        const userEmail = JSON.parse(localStorage.getItem("userEmail"));
        const userName = JSON.parse(localStorage.getItem("userName"));
        const firstName = JSON.parse(localStorage.getItem("firstName"));
        const lastName = JSON.parse(localStorage.getItem("lastName"));
        if (accessToken) {
          setSession(accessToken);
          let user;
          if (sso === "true") {
            user = {
              email: userEmail,
              name: userName,
              firstName: firstName,
              lastName: lastName,
            };
          } else {
            user = await authApi.resolveUser(accessToken);
          }

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      const data: any = await authApi.login({ email, password });
      const date = new Date().getTime();
      const accessToken = data.access_token;
      const refreshToken = {
        token: data.refresh_token,
        expiresIn: date * 0.001 + data.refresh_expires_in,
      };
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem("userEmail", JSON.stringify(email));
      localStorage.setItem("sso", JSON.stringify(false));
      setSession(accessToken);

      let user = await authApi.resolveUser(accessToken);

      localStorage.setItem("userName", JSON.stringify(user?.name));

      const res: any = await creditsQuotaApi.fetchCreditsQuota();
      if (!res.claimedFree) showWalkthrough();
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      console.log(error, "could not set user session..");

      dispatch({
        type: "LOGIN_FAILED",
        payload: {
          error,
        },
      });
    }
  };

  const logout = async (): Promise<void> => {
    await authApi.logout();
    reduxDispatch({ type: "LOGOUT" });
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("sso");
  };

  const register = async (
    email: string,
    firstname: string,
    lastname: string,
    password: string
  ): Promise<void> => {
    await authApi.registerKC({ email, firstname, lastname, password });
    await wait(1000);

    const data: any = await authApi.login({ email, password });
    const date = new Date().getTime();
    const accessToken = data.access_token;
    const refreshToken = {
      token: data.refresh_token,
      expiresIn: date * 0.001 + data.refresh_expires_in,
    };
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
    localStorage.setItem("userEmail", JSON.stringify(email));
    localStorage.setItem("sso", JSON.stringify(false));

    setSession(accessToken);
    let user = await authApi.resolveUser(accessToken);
    await showWalkthrough();
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const loginSSO = async (
    data: any,
    provider: string,
    setIsLoading: any
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const response: any = await authApi.loginSSO({ data, provider });

      const date = new Date().getTime();
      const refreshToken = {
        token: response.refresh_token,
        expiresIn: date * 0.001 + response.expires_in,
      };
      const accessToken = response.access_token;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem("userEmail", JSON.stringify(response?.email));
      localStorage.setItem("userName", JSON.stringify(response?.username));
      localStorage.setItem("firstName", JSON.stringify(response?.firstName));
      localStorage.setItem("lastName", JSON.stringify(response?.lastName));
      localStorage.setItem("sso", JSON.stringify(true));
      setSession(accessToken);
      if (response?.newUser) showWalkthrough();

      const res: any = await creditsQuotaApi.fetchCreditsQuota();
      if (!res.claimedFree) showWalkthrough();
      dispatch({
        type: "REGISTER",
        payload: {
          user: response?.user,
        },
      });
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            email: response?.email,
            name: response?.username,
            username: response?.username,
            firstName: response?.firstName,
            lastName: response?.lastName,
            id: response?.id,
          },
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "could not set user session..");

      dispatch({
        type: "LOGIN_FAILED",
        payload: {
          error,
        },
      });
    }
  };

  const logoutSSO = async (): Promise<void> => {
    reduxDispatch({ type: "LOGOUT" });
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("sso");
    localStorage.removeItem("userName");
    localStorage.removeItem("refreshToken");
    window.location.replace("/");
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await authApi.passwordRecovery(username);

    dispatch({
      type: "PASSWORD_RECOVERY",
    });
  };

  const passwordReset = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await authApi.passwordReset(email, code, newPassword);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const refreshToken = async (): Promise<void> => {
    try {
      const data: any = await authApi.refreshToken();
      const date = new Date().getTime();
      const accessToken = data.access_token;
      const refreshToken = {
        token: data.refresh_token,
        expiresIn: date * 0.001 + data.refresh_expires_in,
      };
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      setSession(accessToken);
      dispatch({
        type: "REFRESH_TOKEN",
      });
    } catch (error) {
      console.log(error, "could not set user session..");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userEmail");
      reduxDispatch({ type: "LOGOUT" });
      dispatch({ type: "LOGOUT" });
    }
  };

  const showWalkthrough = async () => {
    const activePlans = await subscriptionApi.fetchAllActivePlans();
    const freePlan = activePlans?.find((plan) => plan.type === "FREE");
    if (freePlan) {
      localStorage.setItem("showWalkthrough", "true");
      localStorage.setItem("freePlan", `${freePlan?.id}`);
    }
    localStorage.setItem("isTourOpen", "true");
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        logoutSSO,
        register,
        loginSSO,
        passwordRecovery,
        passwordReset,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
