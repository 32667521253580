import axios from "axios";
import { toast } from "react-toastify";

import { getAccessToken } from "src/utils/token";
import { vectorDBConfig } from "src/config";
import {
  SearchIndex,
  VectorIndexes,
  fetchVectorIndexes,
} from "src/types/vectorIndexes";

class VectorIndexesApi {
  async fetchAllVectorIndexes(body: fetchVectorIndexes) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/vector-indexes/all`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchVectorIndex(id: string) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/vector-indexes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async fetchAllModelDimensions() {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(
        `${baseUrl}/vector-indexes/model-dimensions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        const filteredData = response?.data?.filter(
          (model) => model.name === "Universal Sentence Encoder"
        );
        return filteredData;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createVectorIndexes(body: VectorIndexes) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/vector-indexes`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteVectorIndexe(id: string) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.delete(`${baseUrl}/vector-indexes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("Unable to delete try again");
        throw new Error("not deleted");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async searchIndex(body: SearchIndex) {
    try {
      const { baseUrl } = vectorDBConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(
        `${baseUrl}/vector-resources/searchIndex`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved");
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const vectorIndexesApi = new VectorIndexesApi();
