import { combineReducers } from "@reduxjs/toolkit";
import VectorIndexeReducer from "./vectorIndexes/reducers";
import VectorResourceReducer  from "./vectorResources/reducers";
import  VectorClientsReducer  from "./vectorClients/reducers";
import  PaymentHistoryReducer  from "./paymentHistory/reducers";
import  CreditsQuotaReducer  from "./creditsQuota/reducers"; 
import  SubscriptionReducer  from "./subscriptions/reducers";
import  BotsReducer  from "./bots/reducers";


const appReducer = combineReducers({
  vectorIndexes: VectorIndexeReducer,
  vectorResources: VectorResourceReducer,
  vectorClientsReducer: VectorClientsReducer,
  paymentHistory: PaymentHistoryReducer,
  subscriptions: SubscriptionReducer,
  creditQuotas: CreditsQuotaReducer,
  bots: BotsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
