import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";

const Credits = (props: any) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { totalCredits, availableFeatures } = props;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="credits-button"
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderRadius: "20px",
        }}
        variant="outlined"
        data-tut="reactour__credits"
      >
        <Typography>
          Credits Left: {totalCredits !== "NaN" ? totalCredits : "--"}{" "}
        </Typography>
      </Button>
      <Popover
        anchorEl={anchorRef?.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.default,
            mt: 1,
            mx: 0,
            minWidth: "300px",
          }),
        }}
      >
        <Box sx={{ mt: 2 }}>
          <MenuItem
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              mx: 2,
              my: 2,
              borderRadius: "10px",
            })}
          >
            <ListItemText>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  // width={'100%'}
                >
                  Create New Index:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ color: "#0692e2" }}
                >
                  {availableFeatures?.indexes?.unit} Credits
                </Typography>
              </Box>
            </ListItemText>
          </MenuItem>
          <MenuItem
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              mx: 2,
              my: 2,
              borderRadius: "10px",
            })}
          >
            <ListItemText>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Create New Resource:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ color: "#0692e2" }}
                >
                  {availableFeatures?.resources?.unit} Credits
                </Typography>
              </Box>
            </ListItemText>
          </MenuItem>
          <MenuItem
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              mx: 2,
              my: 2,
              borderRadius: "10px",
            })}
          >
            <ListItemText>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  // width={'100%'}
                >
                  Index Query:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ color: "#0692e2" }}
                >
                  {availableFeatures?.queries?.unit} Credits
                </Typography>
              </Box>
            </ListItemText>
          </MenuItem>

          <MenuItem
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              mx: 2,
              my: 2,
              borderRadius: "10px",
            })}
          >
            <ListItemText>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  // width={'100%'}
                >
                  Create Agent:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ color: "#0692e2" }}
                >
                  {availableFeatures?.bots?.unit} Credits
                </Typography>
              </Box>
            </ListItemText>
          </MenuItem>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ p: 2 }}>
          <Button
            component={RouterLink}
            to={"/dashboard/pricing-plans"}
            color="primary"
            fullWidth
            variant="outlined"
            sx={{ borderRadius: "20px", height: "30px" }}
          >
            <LocalMallIcon sx={{ mx: 1 }} fontSize="small" />
            Buy More
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Credits;
