import { Box, Typography } from "@mui/material";

const StepOne = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "10%",
        width: "60%",
        overflow: "scroll",
        height: "60vh",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <img
        src="/assets/images/Hello-rafiki.svg"
        width={300}
        height={300}
        alt="Welcome"
      />
      <Typography
        color="textPrimary"
        variant="h3"
        textAlign="center"
        sx={{ fontWeight: 400, mb: 3 }}
      >
        Welcome to <span style={{ color: "#0692e2" }}>APEXE3</span> - Vector
        Dashboard
      </Typography>
      <Typography color="textPrimary" textAlign="center" mt={"7px"}>
        We're thrilled to have you join us on this journey. Vector Dashboard is
        your one-stop destination for all your indexing and resource management
        needs. Let's get started on this exciting adventure together!
      </Typography>
    </Box>
  );
};

export default StepOne;
