export const FETCH_VECTOR_RESOURCES_START = "FETCH_VECTOR_RESOURCES_START";
export const FETCH_VECTOR_RESOURCES_RECIEVES = "FETCH_VECTOR_RESOURCES_RECIEVES";
export const FETCH_VECTOR_RESOURCES_END = "FETCH_VECTOR_RESOURCES_END";

export const FETCH_VECTOR_RESOURCE_START = "FETCH_VECTOR_RESOURCE_START";
export const FETCH_VECTOR_RESOURCE_RECIEVES = "FETCH_VECTOR_RESOURCE_RECIEVES";
export const FETCH_VECTOR_RESOURCE_END = "FETCH_VECTOR_RESOURCE_END";

export const FETCH_CHUNK_RESOURCE_START = "FETCH_CHUNK_RESOURCE_START";
export const FETCH_CHUNK_RESOURCE_RECIEVES = "FETCH_CHUNK_RESOURCE_RECIEVES";
export const FETCH_CHUNK_RESOURCE_END = "FETCH_CHUNK_RESOURCE_END";

export const GENERATE_EMBEDDINGS_FOR_RESOURCE_START = "GENERATE_EMBEDDINGS_FOR_RESOURCE_START"
export const GENERATE_EMBEDDINGS_FOR_RESOURCE_RECIEVES = "GENERATE_EMBEDDINGS_FOR_RESOURCE_RECIEVES"
export const GENERATE_EMBEDDINGS_FOR_RESOURCE_END = "GENERATE_EMBEDDINGS_FOR_RESOURCE_END"

export const CLEAR_CHUNK_RESOURCES = "CLEAR_CHUNK_RESOURCES"
export const CLEAR_EMBEDDINGS = "CLEAR_EMBEDDINGS"

