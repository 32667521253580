export const FETCH_VECTOR_INDEXES_START = "FETCH_VECTOR_INDEXES_START";
export const FETCH_VECTOR_INDEXES_RECIEVES = "FETCH_VECTOR_INDEXES_RECIEVES";
export const FETCH_VECTOR_INDEXES_END = "FETCH_VECTOR_INDEXES_END";

export const FETCH_MODEL_DIMENSIONS_START = "FETCH_MODEL_DIMENSIONS_START";
export const FETCH_MODEL_DIMENSIONS_RECIEVES = "FETCH_MODEL_DIMENSIONS_RECIEVES";
export const FETCH_MODEL_DIMENSIONS_END = "FETCH_MODEL_DIMENSIONS_END";

export const FETCH_VECTOR_INDEX_START = "FETCH_VECTOR_INDEX_START";
export const FETCH_VECTOR_INDEX_RECIEVES = "FETCH_VECTOR_INDEX_RECIEVES";
export const FETCH_VECTOR_INDEX_END = "FETCH_VECTOR_INDEX_END";

export const SEARCH_INDEX_START = "SEARCH_INDEX_START"
export const SEARCH_INDEX_RECIEVES = "SEARCH_INDEX_RECIEVES"
export const SEARCH_INDEX_END = "SEARCH_INDEX_END"

export const CLEAR_SEARCHES = "CLEAR_SEARCHES"
