import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Container, Link, Typography } from "@mui/material";

import { LoginJWT } from "src/components/authentication/login";

import useAuth from "src/hooks/useAuth";

import gtm from "src/lib/gtm";

const LoginForm: React.FC<any> = (): any => {
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="textPrimary" gutterBottom variant="h4">
              Log in
            </Typography>
            <Typography color="textSecondary" variant="body2">
              New user?{" "}
              <Link
                color="primary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link>
            </Typography>
          </Box>
          <Link
            color="primary"
            component={RouterLink}
            to="/authentication/password-recovery"
            variant="body2"
          >
            Forgot password?
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 1,
          }}
        >
          {platform === "JWT" && <LoginJWT />}
        </Box>
      </Container>
    </Box>
  );
};
export default LoginForm;
