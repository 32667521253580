import { Box, TextField } from "@mui/material";

const CustomTextForm = (props) => {
  const { input, handleInput } = props;
  return (
    <Box>
      <TextField
        label="Welcome Heading"
        value={input?.welcomeHeading}
        onChange={(e) => handleInput("welcomeHeading", e.target.value)}
        sx={{
          width: "100%",
          mb: 3,
        }}
      />

      <TextField
        label="Welcome Subheading"
        value={input?.welcomeSubheading}
        onChange={(e) => handleInput("welcomeSubheading", e.target.value)}
        sx={{
          width: "100%",
          mb: 3,
        }}
      />
    </Box>
  );
};

export default CustomTextForm;
