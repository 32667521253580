import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_VECTOR_INDEXES_START,
  FETCH_VECTOR_INDEXES_RECIEVES,
  FETCH_VECTOR_INDEXES_END,
  FETCH_VECTOR_INDEX_START,
  FETCH_VECTOR_INDEX_RECIEVES,
  FETCH_VECTOR_INDEX_END,
  FETCH_MODEL_DIMENSIONS_START,
  FETCH_MODEL_DIMENSIONS_RECIEVES,
  FETCH_MODEL_DIMENSIONS_END,
  SEARCH_INDEX_START,
  SEARCH_INDEX_RECIEVES,
  SEARCH_INDEX_END,
  CLEAR_SEARCHES,
} from "./action-types";

export const VectorIndexeReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_VECTOR_INDEXES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_VECTOR_INDEXES_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_VECTOR_INDEXES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const SingleVectorIndexReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_VECTOR_INDEX_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_VECTOR_INDEX_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_VECTOR_INDEX_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const VectorModelDimensionsReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_MODEL_DIMENSIONS_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_MODEL_DIMENSIONS_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_MODEL_DIMENSIONS_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const SearchIndexReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case SEARCH_INDEX_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case SEARCH_INDEX_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case SEARCH_INDEX_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    case CLEAR_SEARCHES: {
      return {
        loading: false,
        data: [],
        error: "",
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  VectorIndexeReducer,
  VectorModelDimensionsReducer,
  SingleVectorIndexReducer,
  SearchIndexReducer,
});
