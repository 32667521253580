import { FC, ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";

import { Box, Button, Divider, Typography } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";

import Wizard from "src/pages/dashboard/Wizard";
import DashboardSidebar from "./DashboardSidebar";

import useSettings from "src/hooks/useSettings";
import useCreditsQuota from "src/hooks/useCreditsQuota";

import { useDispatch } from "react-redux";
import { fetchCredits } from "src/store/creditsQuota/actions";

import { subscriptionApi } from "src/__Api__/subscriptionApi";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "0px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

// const coinProfileSelector = (state) => get( state, 'ui.CoinProfilerDialog.coin' );

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  // const coinProfileState = useSelector( coinProfileSelector );
  const dispatch = useDispatch();
  const [isClaimed, setIsClaimed] = useState(
    localStorage.getItem("showWalkthrough") === "true" ? true : false
  );
  const freePlanId = localStorage.getItem("freePlan");
  const { creditsQuota } = useCreditsQuota();
  const [isTourOpen, setIsTourOpen] = useState(
    localStorage.getItem("isTourOpen") === "true" ? true : false
  );
  const { settings } = useSettings();
  const { theme } = settings;
  const closeTour = () => {
    setIsTourOpen(false);
    localStorage.setItem("isTourOpen", "false");
  };

  const closeWizard = async () => {
    await dispatch(fetchCredits());
    setIsClaimed(false);
    localStorage.setItem("showWalkthrough", "false");
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  // useScrollReset();
  const accentColor = "#0692e2";

  const tourConfig = [
    {
      selector: '[data-tut="reactour__credits"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h5" sx={{ color: "#0692e2" }}>
              {" "}
              Credits{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            These are your available credits.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "bottom",
    },
    {
      selector: '[data-tut="reactour__indexes"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h5" sx={{ color: "#0692e2" }}>
              {" "}
              Vector Indexes{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>

          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can create new indexes.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
    {
      selector: '[data-tut="reactour__resources"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h5" sx={{ color: "#0692e2" }}>
              {" "}
              Vector Resources{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can create new resources.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
    {
      selector: '[data-tut="reactour__bots"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h5" sx={{ color: "#0692e2" }}>
              {" "}
              Agents{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can create new bots.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
    {
      selector: '[data-tut="reactour__secrets"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h5" sx={{ color: "#0692e2" }}>
              {" "}
              Secret Keys{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can generate new keys.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
    {
      selector: '[data-tut="reactour__payments"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h6" sx={{ color: "#0692e2", pt: 0.5 }}>
              {" "}
              Payment History{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Skip
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can see history of your payments.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
    {
      selector: '[data-tut="reactour__quota"]',
      content: () => (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="h6" sx={{ color: "#0692e2", pt: 0.5 }}>
              {" "}
              Quota History{" "}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: "20px", height: "max-content" }}
              onClick={closeTour}
            >
              Finish Tour
            </Button>
          </Box>
          <Divider />
          <Typography sx={{ mt: 1 }}>
            {" "}
            From here, you can see history of your quota usage.{" "}
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme === "DARK" ? "#19191e" : "rgba(255, 255, 255)",
        color: theme === "DARK" ? "white" : "#29292e",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
      },
      position: "right",
    },
  ];

  return isClaimed && freePlanId ? (
    <Wizard
      handleClaimStatus={() => subscriptionApi?.claimFreeCredits()}
      setIsClaimed={setIsClaimed}
      handleGetCredits={closeWizard}
      claimedFree={creditsQuota?.claimedFree}
    />
  ) : (
    <>
      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isTourOpen}
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        showCloseButton={false}
        lastStepNextButton={({ closeTour }) => (
          <Button
            variant="outlined"
            sx={{ borderRadius: "20px", height: "max-content" }}
            onClick={closeTour}
          >
            Finish
          </Button>
        )}
        closeWithMask={false}
      />
      <DashboardLayoutRoot>
        <DashboardSidebar
          totalCredits={parseFloat(creditsQuota?.availableCredis)?.toFixed(2)}
          availableFeatures={creditsQuota?.availableFeatures}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              {/* <CoinProfileDialog coin={ coinProfileState } /> */}
              <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayout;
