import { useEffect, useRef } from "react";

import { botWidgetConfig } from "src/config";

function Bot() {
  const { baseUrl } = botWidgetConfig();
  const queryParams = new URLSearchParams(window.location.search);
  const decodedHashValue = decodeURIComponent(queryParams.get("query"));
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        iframeRef.current.contentWindow.postMessage(window.location.href, "*");
      };
    }
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <iframe
        ref={iframeRef}
        src={`${baseUrl}?query=${encodeURIComponent(decodedHashValue)}`}
        title="Agent Widget"
        style={{
          width: "100%",
          height: "100vh",
          border: "0px",
        }}
      />
    </div>
  );
}

export default Bot;
