import { type FC } from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import DialogLayout from "src/components/dashboard/DialogLayout";

const termsContent = {
  title: "Terms and Conditions ",
  desc: 'These Terms and Conditions ("Terms") govern your access to and use of the Apexe3-Vector Dashboard Platform ("Platform"), provided by A.L.I.C.E, an AI assistant for capital markets. By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use the Platform.',
  sections: [
    {
      title: "Acceptance of Terms",
      content: [
        "By accessing or using the Platform, you agree to be bound by these Terms.",
        "These Terms may be updated by A.L.I.C.E from time to time without notice. Your continued use of the Platform after any changes to these Terms constitutes acceptance of those changes.",
      ],
    },
    {
      title: "Access and Use of the Platform",
      content: [
        "You must be at least 18 years old to access or use the Platform.",
        "You agree to provide accurate and complete information when using the Platform.",
        "You are responsible for maintaining the security of your account and password. You agree not to share your account credentials with anyone else.",
        "You agree not to use the Platform for any illegal or unauthorized purpose.",
      ],
    },
    {
      title: "Subscription Plans and Credits",
      content: [
        "The Platform may offer subscription plans and credits for access to certain features and services.",
        "Subscription plans and credits are non-transferable and may not be redeemed for cash.",
        "A.L.I.C.E reserves the right to modify the pricing of subscription plans and credits at any time.",
        "Subscription fees are payable in advance and are non-refundable.",
      ],
    },
    {
      title: "Intellectual Property",
      content: [
        "The Platform and its contents, including but not limited to text, graphics, images, and software, are the property of A.L.I.C.E and are protected by copyright and other intellectual property laws.",
        "You may not modify, reproduce, distribute, or create derivative works based on the Platform or its contents without the prior written consent of A.L.I.C.E.",
      ],
    },
    {
      title: "Limitation of Liability",
      content: [
        "To the extent permitted by law, A.L.I.C.E shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the Platform.",
        "In no event shall A.L.I.C.E's total liability to you exceed the amount paid by you for access to the Platform.",
      ],
    },
    {
      title: "Indemnification",
      content: [
        "You agree to indemnify and hold harmless A.L.I.C.E, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses arising out of or in any way connected with your use of the Platform.",
      ],
    },
    {
      title: "Governing Law",
      content: [
        "These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.",
      ],
    },
    {
      title: "Severability",
      content: [
        "If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms shall remain in full force and effect.",
      ],
    },
    {
      title: "Contact Information",
      content: [
        "If you have any questions or concerns about these Terms, please contact us at [Contact Email].",
      ],
    },
  ],
};
const TermsAndConditions: FC<any> = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  return (
    <DialogLayout
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title=""
      width="900px"
    >
      <>
        <Container
          sx={{
            display: "flex",
            gap: 2,
            mt: 3,
            mb: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {termsContent?.title}
              </Typography>
              <Typography align="justify" gutterBottom>
                {termsContent?.desc}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {termsContent?.sections?.map((section, index) => (
                <div key={index}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography variant="body1" paragraph color={"primary"}>
                      <strong>{`${index + 1}.`}</strong>
                    </Typography>
                    <Typography variant="body1" paragraph color={"primary"}>
                      <strong>{section.title}</strong>
                    </Typography>
                  </Box>
                  {section.content.map((paragraph, pIndex) => (
                    <Box sx={{ display: "flex", gap: 1, ml: 1 }}>
                      <Typography key={pIndex} variant="body1" paragraph>
                        {`${index + 1}.${pIndex + 1}`}
                      </Typography>
                      <Typography key={pIndex} variant="body1" paragraph>
                        {paragraph}
                      </Typography>
                    </Box>
                  ))}
                </div>
              ))}
            </Grid>
          </Grid>
        </Container>
      </>
    </DialogLayout>
  );
};

export default TermsAndConditions;
