import React from "react";
import { Helmet } from "react-helmet-async";

import { Button, Box, Stepper, Step, StepLabel } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import StepOne from "src/components/wizard/StepOne";
import StepTwo from "src/components/wizard/StepTwo";
import StepThree from "src/components/wizard/StepThree";

const pageTitle = "Wizard";
const steps = ["Welcome", "Claim your credits", "Finish"];

const Wizard: React.FC<any> = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { handleClaimStatus, handleGetCredits } = props;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Helmet>
        <title>APEXE3 Vector Database - {pageTitle}</title>
      </Helmet>
      <Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {activeStep === 0 && <StepOne />}
          {activeStep === 1 && <StepTwo />}
          {activeStep === 2 && <StepThree />}

          <Stepper
            activeStep={activeStep}
            sx={{ position: "fixed", top: "83%", width: "68%" }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              position: "fixed",
              top: "13%",
              width: "68%",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              <ArrowCircleLeftIcon fontSize="large" />
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={async () => {
                if (activeStep === 1) {
                  // setIsClaimed(true)
                  await handleClaimStatus();
                  handleNext();
                } else if (activeStep === 2) {
                  await handleGetCredits();
                } else {
                  handleNext();
                }
              }}
              size="large"
              color="primary"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                height: "max-content",
                fontSize: "medium",
              }}
            >
              {activeStep === 0 && "Next"}
              {activeStep === 1 && "Claim Now"}
              {activeStep === 2 && "Finish"}
              {/* {activeStep 2== steps.length - 1 ? 'Finish' : 'Next'} */}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Wizard;
