import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_PAYMENT_HISTORY_START,
  FETCH_PAYMENT_HISTORY_RECIEVES,
  FETCH_PAYMENT_HISTORY_END,
} from "./action-types";

export const PaymentHistoryReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_PAYMENT_HISTORY_START: {
      return {
        loading: true,
        data: { ...state.data },
        error: "",
      };
    }
    case FETCH_PAYMENT_HISTORY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_PAYMENT_HISTORY_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  PaymentHistoryReducer,
});
