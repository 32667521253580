import { Box, Container, Typography } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const ChatScreen = (props) => {
  const { botImageUrl, logoUrl, modeColors } = props;
  return (
    <Container
      sx={(theme) => ({
        height: "100%",
        width: "100%",
        backgroundColor: modeColors.bgPrimary,
        display: "flex",
        flexDirection: "column",
        p: 3,
        border: `1px solid ${modeColors.bgSecondary}`,
        borderRadius: "20px",
        position: "relative",
      })}
    >
      <Box
        sx={(theme) => ({
          height: "10%",
          width: "100%",
          backgroundColor: modeColors.bgSecondary,
          borderRadius: "999px",
          display: "flex",
          px: 2,
          justifyContent: "space-between",
        })}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            ml: "3px",
            px: 0,
          }}
        >
          <img
            src={logoUrl ? logoUrl : "/static/Apex_Logo_2022_large.png"}
            style={{
              width: "70px",
              height: "auto",
              cursor: "pointer",
            }}
            alt="logo of sitemark"
          />
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: modeColors.bgSecondary,
            padding: 1,
            borderRadius: "50%",
            margin: "auto",
            width: 45,
            height: 45,
          })}
          component={"img"}
          alt="Bot Icon"
          src={botImageUrl ? botImageUrl : "/static/robot.png"}
        />
      </Box>
      <Box
        sx={(theme) => ({
          height: "80%",
          width: "100%",
          backgroundColor: modeColors.bgPrimary,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 5,
          gap: 3,
        })}
      >
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "end", px: 2 }}
        >
          <Typography
            sx={{
              py: 1,
              px: 2,
              borderRadius: "20px 20px 20px 20px",
              bgcolor: modeColors.messageBgColor,
              color: modeColors.messageTextColor,
              width: "max-content",
            }}
          >
            Hi there!
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            px: 2,
          }}
        >
          <Box
            sx={(theme) => ({
              width: 28,
              height: 30,
            })}
            component={"img"}
            alt="Bot Icon"
            src={botImageUrl ? botImageUrl : "/static/robot.png"}
          />
          <Typography
            sx={{ px: 2, color: modeColors.textPrimary, width: "max-content" }}
          >
            Hello! How can I assist you today?
          </Typography>
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "end", px: 2 }}
        >
          <Typography
            sx={{
              py: 1,
              px: 2,
              borderRadius: "20px 20px 20px 20px",
              bgcolor: modeColors.messageBgColor,
              color: modeColors.messageTextColor,
              width: "max-content",
            }}
          >
            What's the weather like today?
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            px: 2,
          }}
        >
          <Box
            sx={(theme) => ({
              width: 30,
              height: 30,
            })}
            component={"img"}
            alt="Bot Icon"
            src={botImageUrl ? botImageUrl : "/static/robot.png"}
          />
          <Typography
            sx={{ px: 2, color: modeColors.textPrimary, width: "max-content" }}
          >
            The weather today is sunny with a high of 25°C. Perfect for a walk
            outside!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "10%",
          width: "100%",
          backgroundColor: modeColors.bgPrimary,
          borderRadius: "999px",
          border: `1px solid ${modeColors.border}`,
          display: "flex",
          p: 2,
          justifyContent: "space-between",
        })}
      >
        <Typography
          sx={{
            fontSize: "medium",
            color: "#9c9a9a",
          }}
        >
          Type something here...
        </Typography>
        <SendRoundedIcon fontSize="medium" color="primary" />
      </Box>
    </Container>
  );
};

export default ChatScreen;
