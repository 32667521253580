import { Box, Typography } from "@mui/material";

const StepThree = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "10%",
        width: "60%",
        overflow: "scroll",
        height: "60vh",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <img
        src="/assets/images/Happy-announcement-rafiki.svg"
        width={300}
        height={300}
        alt="Free Credits Claimed"
      />
      <Typography
        color="textPrimary"
        variant="h4"
        textAlign="center"
        sx={{ fontWeight: 400, mb: 3 }}
      >
        <span style={{ color: "#0692e2" }}>Congratulations</span> on claiming
        your free credits!
      </Typography>
      <Typography color="textPrimary" textAlign="center" mt={"7px"}>
        You're now fully equipped to delve deeper into Vector Dashboard and
        unleash its boundless potential. Keep exploring, creating, and
        discovering innovative ways to leverage our platform. We're eagerly
        anticipating the remarkable accomplishments you'll achieve!{" "}
      </Typography>
    </Box>
  );
};

export default StepThree;
