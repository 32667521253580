import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_VECTOR_CLIENTS_START,
  FETCH_VECTOR_CLIENTS_RECIEVES,
  FETCH_VECTOR_CLIENTS_END,
} from "./action-types";

export const VectorClientsReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_VECTOR_CLIENTS_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_VECTOR_CLIENTS_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_VECTOR_CLIENTS_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  VectorClientsReducer,
});
