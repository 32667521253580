import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "src/store";
import { fetchCredits } from "src/store/creditsQuota/actions";

const useCreditsQuota = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const { data: creditsQuota = {} } = useSelector(
    (state: any) => state?.creditQuotas?.CreditsQuotaReducer
  );

  const fetchCreditsQuota = async () => {
    dispatch(fetchCredits());
  };

  useEffect(() => {
    if (creditsQuota?.data?.length === 0) {
      fetchCreditsQuota();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return {
    creditsQuota,
    isLoading,
  };
};

export default useCreditsQuota;
