import { createTheme, responsiveFontSizes, Theme, ThemeOptions } from '@mui/material/styles';
import { Direction } from '@mui/system';

import { THEMES } from '../constants';
import { darkShadows, lightShadows } from './shadows';

const apexColors = {
  primaryBlue: '#0992e2',
  backgroundBlue2: 'rgba(35, 35, 40, 1)',
  backgroundDark: '#19191e',
  secondaryBackgroundDark: '#29292e',
  borderColorSplit: 'rgba(255, 255, 255, 0.05)',
  backgroundLight: 'rgba(255, 255, 255)',
  secondaryBackgroundLight: '#e1e1e3',
  apexeBlue:'#0692e2',
  secondaryBlue: '#d5e9f9'
};

const borders = {
  lightEdge: '1px solid rgba(255,255,255,0.2)',
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          border: borders.lightEdge,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          },
        },
        root: {
          borderRadius: '0 !important',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          },
        },
        root: {
          borderRadius: 0,
        }
      }
    },
    MuiTooltip: {
      // tooltip: {
        styleOverrides: {
          tooltip: {
            color: '#ffffff',
            backgroundColor: apexColors.backgroundDark,
            borderRadius: 0,
            border: borders.lightEdge,
          },
        },
      // }
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
          // height: '400px',
        },
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: apexColors.backgroundBlue2,
          border: borders.lightEdge,
          borderRadius: 0,
        },
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.DARK]: {
    components: {
      ...baseOptions.components,
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: apexColors.backgroundDark,
        paper: apexColors.secondaryBackgroundDark,
      },
      divider: apexColors.borderColorSplit,
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: apexColors.primaryBlue,
      },
      secondary: {
        contrastText: '#919EAB',
        main: "#2C3034"
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
    },
    shadows: darkShadows
  },
  [THEMES.LIGHT]: {
    components: {
      ...baseOptions.components,
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: apexColors.backgroundLight,
        paper: apexColors.secondaryBackgroundLight,
      },
      divider: '#0692e033',
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: apexColors.apexeBlue,
      },
      secondary: {
        contrastText: apexColors.secondaryBackgroundDark,
        main: apexColors.secondaryBlue
      },
      text: {
        primary: apexColors.secondaryBackgroundDark,
        secondary: apexColors.backgroundDark
      },
    },
    shadows: lightShadows
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];
  // let themeOptions = themesOptions[THEMES.LIGHT];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.DARK];
  }

  config.roundedCorners = false;

  let theme = createTheme(
    {
      ...baseOptions,
      ...themeOptions,
      ...{
        direction: config.direction
      }
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};