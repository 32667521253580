import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_CREDITS_QUOTA_START,
  FETCH_CREDITS_QUOTA_RECIEVES,
  FETCH_CREDITS_QUOTA_END,
  FETCH_QUOTA_HISTORY_END,
  FETCH_QUOTA_HISTORY_RECIEVES,
  FETCH_QUOTA_HISTORY_START,
  FETCH_QUOTA_CONSUMPTION_END,
  FETCH_QUOTA_CONSUMPTION_RECIEVES,
  FETCH_QUOTA_CONSUMPTION_START,
} from "./action-types";

export const CreditsQuotaReducer = (
  state: { loading: boolean; data: any; error: any } = {
    loading: false,
    data: { data: [] },
    error: "",
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_CREDITS_QUOTA_START: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case FETCH_CREDITS_QUOTA_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_CREDITS_QUOTA_END: {
      return {
        loading: false,
        data: {},
        error,
      };
    }
    default:
      return state;
  }
};

export const QuotaHistoryReducer = (
  state: { loading: boolean; data: any; error: any } = {
    loading: false,
    data: { data: [] },
    error: "",
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_QUOTA_HISTORY_START: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case FETCH_QUOTA_HISTORY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_QUOTA_HISTORY_END: {
      return {
        loading: false,
        data: {},
        error,
      };
    }
    default:
      return state;
  }
};

export const QuotaConsumptionReducer = (
  state: { loading: boolean; data: any; error: any } = {
    loading: false,
    data: { data: [] },
    error: "",
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_QUOTA_CONSUMPTION_START: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case FETCH_QUOTA_CONSUMPTION_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_QUOTA_CONSUMPTION_END: {
      return {
        loading: false,
        data: {},
        error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  CreditsQuotaReducer,
  QuotaHistoryReducer,
  QuotaConsumptionReducer,
});
