import { Box } from "@mui/material";

const WidgetLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "55%",
        gap: 2,
        alignItems: "end",
      }}
    >
      {children}
    </Box>
  );
};

export default WidgetLayout;
