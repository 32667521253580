import { isString } from "lodash";

import { creditsQuotaApi } from "src/__Api__/creditsQuotaApi";

import {
  FETCH_CREDITS_QUOTA_START,
  FETCH_CREDITS_QUOTA_RECIEVES,
  FETCH_CREDITS_QUOTA_END,
  FETCH_QUOTA_HISTORY_END,
  FETCH_QUOTA_HISTORY_RECIEVES,
  FETCH_QUOTA_HISTORY_START,
  FETCH_QUOTA_CONSUMPTION_END,
  FETCH_QUOTA_CONSUMPTION_RECIEVES,
  FETCH_QUOTA_CONSUMPTION_START,
} from "./action-types";

export const fetchCredits = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CREDITS_QUOTA_START });

    const result = await creditsQuotaApi.fetchCreditsQuota();
    if (isString(result)) {
      dispatch({
        type: FETCH_CREDITS_QUOTA_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_CREDITS_QUOTA_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CREDITS_QUOTA_END,
      data: [],
      error,
    });
  }
};

export const fetchQuotaHistory = (body, setLoading) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_QUOTA_HISTORY_START });
    setLoading(true);
    const result = await creditsQuotaApi.fetchQuotaHistory(body);
    setLoading(false);
    if (isString(result)) {
      dispatch({
        type: FETCH_QUOTA_HISTORY_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_QUOTA_HISTORY_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_QUOTA_HISTORY_END,
      data: [],
      error,
    });
  }
};

export const fetchQuotaConsumption =
  (claimId, setDetailsLoading) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_QUOTA_CONSUMPTION_START });
      setDetailsLoading(true);
      const result = await creditsQuotaApi.fetchQuotaConsumption(claimId);
      setDetailsLoading(false);
      if (isString(result)) {
        dispatch({
          type: FETCH_QUOTA_CONSUMPTION_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_QUOTA_CONSUMPTION_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_QUOTA_CONSUMPTION_END,
        data: [],
        error,
      });
    }
  };
