import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Trash from "src/icons/Trash";
interface BasicInfoFormProps {
  input: any;
  handleInput: (key: string, value: any) => void;
  submitClicked: boolean;
  vectorIndexOptions: any[];
  botThemeOptions: any[];
  urlInput: string;
  handleUrlInputChange: (any) => void;
  handleUrlKeyDown: (any) => void;
  allowedUrls: any[];
  handleRemoveUrl: (any) => void;
  linkInput: any;
  handleLinkInput: (key: string, value: any) => void;
  addLinkClicked: boolean;
  handleAddLink: (any) => void;
  links: any[];
  handleRemoveLink: (any) => void;
}

const BasicInfoForm = (props: BasicInfoFormProps) => {
  const {
    input,
    handleInput,
    submitClicked,
    vectorIndexOptions,
    botThemeOptions,
    urlInput,
    handleUrlInputChange,
    handleUrlKeyDown,
    allowedUrls,
    handleRemoveUrl,
    linkInput,
    handleLinkInput,
    addLinkClicked,
    handleAddLink,
    links,
    handleRemoveLink,
  } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        pt: 2,
      }}
    >
      <TextField
        label="Agent Title"
        value={input?.title}
        onChange={(e) => handleInput("title", e.target.value)}
        sx={{
          width: "100%",
          mb: 3,
        }}
        error={submitClicked && !input.title}
        helperText={
          submitClicked && !input.title ? "Title should not be empty." : ""
        }
        required
      />

      <Autocomplete
        options={vectorIndexOptions}
        getOptionLabel={(option: any) => option.label}
        value={
          vectorIndexOptions.find(
            (option) => option.id === input.vectorIndex
          ) || null
        }
        onChange={(event, newValue) => {
          if (newValue) {
            handleInput("vectorIndex", newValue.id);
          }
        }}
        clearIcon={null}
        noOptionsText="No vector indexes available"
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
            })}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Select Vector Index"
            sx={{
              width: "100%",
              mb: 3,
            }}
            error={submitClicked && !input.vectorIndex}
            helperText={
              submitClicked && !input.vectorIndex
                ? "You must select a vector index."
                : ""
            }
          />
        )}
      />
      <Autocomplete
        options={botThemeOptions}
        getOptionLabel={(option: any) => option.label}
        value={
          botThemeOptions.find((option) => option.id === input.botThemeId) ||
          null
        }
        onChange={(event, newValue) => {
          if (newValue) {
            handleInput("botThemeId", newValue.id);
          }
        }}
        clearIcon={null}
        noOptionsText="No agent themes available"
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
            })}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Select Agent Theme"
            sx={{
              width: "100%",
              mb: 3,
            }}
            error={submitClicked && !input.botThemeId}
            helperText={
              submitClicked && !input.botThemeId
                ? "You must select a agent theme."
                : ""
            }
          />
        )}
      />

      <TextField
        label="Heading"
        value={input?.heading}
        onChange={(e) => handleInput("heading", e.target.value)}
        sx={{
          width: "100%",
          mb: 3,
        }}
      />
      <TextField
        label="Subheading"
        value={input?.subheading}
        onChange={(e) => handleInput("subheading", e.target.value)}
        sx={{
          width: "100%",
          mb: 3,
        }}
      />
      <FormControl sx={{ mb: 3, mx: 1 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Choose Mode
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={input.mode}
          onChange={(e) => {
            handleInput("mode", e.target.value);
          }}
        >
          <FormControlLabel
            value="dark"
            control={<Radio size="small" />}
            label="DARK"
          />
          <FormControlLabel
            value="light"
            control={<Radio size="small" />}
            label="LIGHT"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        label="Allowed URLs"
        value={urlInput}
        onChange={handleUrlInputChange}
        onKeyDown={handleUrlKeyDown}
        sx={{
          width: "100%",
          mb: 3,
        }}
        helperText="Press Enter to add URL"
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          mb: allowedUrls.length ? 3 : 0,
        }}
      >
        {allowedUrls.map((url, index) => (
          <Chip
            key={index}
            label={url}
            onDelete={() => handleRemoveUrl(url)}
            deleteIcon={<CloseIcon />}
          />
        ))}
      </Box>

      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Links</Typography>
      </Divider>

      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          label="Link Title"
          value={linkInput.title}
          onChange={(e) => handleLinkInput("title", e.target.value)}
          sx={{
            width: "100%",
            mb: 3,
          }}
          error={addLinkClicked && !linkInput.title}
          helperText={
            addLinkClicked && !linkInput.title
              ? "Title should not be empty."
              : ""
          }
        />
        <TextField
          label="Link Value"
          value={linkInput.value}
          onChange={(e) => handleLinkInput("value", e.target.value)}
          sx={{
            width: "100%",
            mb: 3,
          }}
          error={addLinkClicked && !linkInput.value}
          helperText={
            addLinkClicked && !linkInput.value
              ? "Value should not be empty."
              : ""
          }
        />
        <IconButton
          sx={{ height: "max-content", mt: 0.5 }}
          onClick={handleAddLink}
        >
          <AddCircleIcon
            sx={(theme) => ({
              fontSize: "30px",
              cursor: "pointer",
              color: theme.palette.primary.main,
            })}
          />
        </IconButton>
      </Box>

      {links.length !== 0 &&
        links.map((link, index) => (
          <Box sx={{ display: "flex", gap: 2 }} key={index}>
            <TextField
              label="Link Title"
              disabled
              value={link.title}
              sx={{
                width: "100%",
                mb: 3,
              }}
            />
            <TextField
              label="Link Value"
              disabled
              value={link.value}
              sx={{
                width: "100%",
                mb: 3,
              }}
            />
            <IconButton
              sx={{ height: "max-content", mt: 0.5 }}
              onClick={() => handleRemoveLink(link.title)}
            >
              <Trash style={{ color: "#F32B1F" }} />
            </IconButton>
          </Box>
        ))}
    </Box>
  );
};

export default BasicInfoForm;
