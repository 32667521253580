import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StepTwo = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: "10%",
        width: "60%",
        height: "60vh",
        overflow: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <img
        src="/assets/images/Credit-card-rafiki.svg"
        width={300}
        height={300}
        alt="Features of Vector Dashboard"
      />
      <Typography
        color="textPrimary"
        variant="h4"
        textAlign="center"
        sx={{ fontWeight: 400, mb: 3 }}
      >
        You've got <span style={{ color: "#0692e2" }}>free credits</span> to
        kickstart your Vector Dashboard experience.
      </Typography>
      <Typography color="textPrimary" textAlign="center" mt={"7px"}>
        It's your chance to explore the full potential of Vector Dashboard and
        unleash the power of indexing at your fingertips. With these credits,
        you can:{" "}
      </Typography>
      <Box sx={{ width: "94%", display: "flex" }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Create New Indexes" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Create New Resources" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Search a Query" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Create Agents" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default StepTwo;
