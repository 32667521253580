import { combineReducers } from "@reduxjs/toolkit";

import {
  FETCH_ACTIVE_PLANS_END,
  FETCH_ACTIVE_PLANS_RECIEVES,
  FETCH_ACTIVE_PLANS_START,
  FETCH_SUBSCRIPTION_END,
  FETCH_SUBSCRIPTION_RECIEVES,
  FETCH_SUBSCRIPTION_START,
} from "./action-types";

export const SubscriptionReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_ACTIVE_PLANS_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_ACTIVE_PLANS_RECIEVES: {
      const paidPlans = data?.filter((plan) => plan.type !== "FREE");
      const freePlan = data?.filter((plan) => plan.type === "FREE");
      return {
        loading: false,
        data: { paidPlans: paidPlans, freePlan: freePlan },
        error: "",
      };
    }
    case FETCH_ACTIVE_PLANS_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const SubscriptionDetailsReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_SUBSCRIPTION_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_SUBSCRIPTION_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_SUBSCRIPTION_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  SubscriptionReducer,
  SubscriptionDetailsReducer,
});
