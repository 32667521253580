import { Box, Container, Typography } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const BotWidget = (props) => {
  const { botInput, botImageUrl, logoUrl, modeColors } = props;
  return (
    <Container
      sx={(theme) => ({
        height: "100%",
        width: "100%",
        backgroundColor: modeColors.bgPrimary,
        display: "flex",
        flexDirection: "column",
        p: 3,
        border: `1px solid ${modeColors.bgSecondary}`,
        borderRadius: "20px",
        position: "relative",
      })}
    >
      <Box
        sx={(theme) => ({
          height: "10%",
          width: "100%",
          backgroundColor: modeColors.bgSecondary,
          borderRadius: "999px",
          display: "flex",
          px: 2,
          justifyContent: "space-between",
        })}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            ml: "3px",
            px: 0,
          }}
        >
          <img
            src={logoUrl ? logoUrl : "/static/Apex_Logo_2022_large.png"}
            style={{
              width: "70px",
              height: "auto",
              cursor: "pointer",
            }}
            alt="logo of sitemark"
          />
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: modeColors.bgSecondary,
            padding: 1,
            borderRadius: "50%",
            margin: "auto",
            width: 45,
            height: 45,
          })}
          component={"img"}
          alt="Bot Icon"
          src={botImageUrl ? botImageUrl : "/static/robot.png"}
        />
      </Box>
      <Box
        sx={(theme) => ({
          height: "80%",
          width: "100%",
          backgroundColor: modeColors.bgPrimary,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Typography
          variant="h2"
          color={"primary"}
          sx={{ fontWeight: "lighter" }}
        >
          {botInput?.welcomeHeading ? botInput?.welcomeHeading : "Welcome!"}
        </Typography>
        <Typography sx={{ fontSize: "small", color: modeColors.textPrimary }}>
          {" "}
          {botInput?.welcomeSubheading
            ? botInput?.welcomeSubheading
            : "How can I assist you today?"}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          height: "10%",
          width: "100%",
          backgroundColor: modeColors.bgPrimary,
          borderRadius: "999px",
          border: `1px solid ${modeColors.border}`,
          display: "flex",
          p: 2,
          justifyContent: "space-between",
        })}
      >
        <Typography
          sx={{
            fontSize: "medium",
            color: "#9c9a9a",
          }}
        >
          Type something here...
        </Typography>
        <SendRoundedIcon fontSize="medium" color="primary" />
      </Box>
    </Container>
  );
};

export default BotWidget;
