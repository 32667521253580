import { Box, Button, CircularProgress } from "@mui/material";

const FormLayout = ({
  children,
  isDisabled,
  onClick,
  isUpdateForm,
  loadingStates,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "45%",
        position: "relative",
        gap: 3,
      }}
    >
      {children}
      <Button
        disabled={isDisabled}
        sx={{
          borderRadius: "20px",
          bottom: 0,
          zIndex: 1,
        }}
        variant="outlined"
        onClick={onClick}
      >
        {!isUpdateForm ? (
          !loadingStates.createBot ? (
            "Create Agent"
          ) : (
            <Box
              sx={(theme) => ({
                color:
                  theme.palette.mode === "dark"
                    ? "white"
                    : theme.palette.primary.main,
              })}
            >
              <CircularProgress value={100} size="15px" color="inherit" />
            </Box>
          )
        ) : !loadingStates.updateBot ? (
          "Update Agent"
        ) : (
          <Box
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? "white"
                  : theme.palette.primary.main,
            })}
          >
            <CircularProgress value={100} size="15px" color="inherit" />
          </Box>
        )}
      </Button>
    </Box>
  );
};

export default FormLayout;
