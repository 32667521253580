import { Box, Button, CircularProgress, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const MediaForm = (props) => {
  const {
    loadingStates,
    botImageUrl,
    handleBotImageUpload,
    isUpdateForm,
    logoUrl,
    handleLogoUpload,
  } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button
        color="primary"
        variant="contained"
        component="label"
        sx={{ borderRadius: "20px", mb: 3, color: "white" }}
        startIcon={
          loadingStates.uploadBotImage ? (
            <CircularProgress size="15px" color="inherit" />
          ) : (
            <CloudUploadIcon />
          )
        }
        disabled={loadingStates.uploadBotImage}
      >
        {!loadingStates.uploadBotImage
          ? botImageUrl
            ? botImageUrl.split("/")[botImageUrl.split("/").length - 1]
            : "Upload Agent Image"
          : "Please Wait..."}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => handleBotImageUpload(e, isUpdateForm)}
        />
      </Button>
      <Button
        color="primary"
        variant="contained"
        component="label"
        sx={{ borderRadius: "20px", mb: 3, color: "white" }}
        startIcon={
          loadingStates.uploadLogo ? (
            <CircularProgress size="15px" color="inherit" />
          ) : (
            <CloudUploadIcon />
          )
        }
        disabled={loadingStates.uploadLogo}
      >
        {!loadingStates.uploadLogo
          ? logoUrl
            ? logoUrl.split("/")[logoUrl.split("/").length - 1]
            : "Upload Logo"
          : "Please Wait..."}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => handleLogoUpload(e, isUpdateForm)}
        />
      </Button>
    </Box>
  );
};

export default MediaForm;
