import type { FC } from 'react';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = styled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
  </LogoRoot>
);

export default Logo;
