import { toast } from "react-toastify";
import { isString } from "lodash";

import { botsApi } from "src/__Api__/botsApi";

import {
  FETCH_ALL_BOTS_START,
  FETCH_ALL_BOTS_END,
  FETCH_ALL_BOTS_RECIEVES,
  FETCH_ALL_BOT_THEMES_START,
  FETCH_ALL_BOT_THEMES_END,
  FETCH_ALL_BOT_THEMES_RECIEVES,
  FETCH_SINGLE_BOT_END,
  FETCH_SINGLE_BOT_RECIEVES,
  FETCH_SINGLE_BOT_START,
} from "./action-types";

import { CreateBot, FetchBots } from "src/types/bots";

export const fetchAllBots =
  (handleLoadingStates: any, body: FetchBots) => async (dispatch) => {
    handleLoadingStates("fetchAllBots", true);
    try {
      dispatch({ type: FETCH_ALL_BOTS_START });
      const result = await botsApi.fetchAllBots(body);
      handleLoadingStates("fetchAllBots", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_ALL_BOTS_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_ALL_BOTS_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (e) {
      handleLoadingStates("fetchAllBots", false);
      console.log(e);
    }
  };

export const fetchBot =
  (id: string, handleLoadingStates: any) => async (dispatch) => {
    handleLoadingStates("fetchBot", true);
    try {
      dispatch({ type: FETCH_SINGLE_BOT_START });
      const result = await botsApi.fetchBot(id);
      handleLoadingStates("fetchBot", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_SINGLE_BOT_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_SINGLE_BOT_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (e) {
      handleLoadingStates("fetchBot", false);
      console.log(e);
    }
  };

export const createBot =
  (handleLoadingStates: any, body: CreateBot, fetchBody: any) =>
  async (dispatch) => {
    handleLoadingStates("createBot", true);
    try {
      await botsApi.createBot(body);
      handleLoadingStates("createBot", false);

      dispatch(fetchAllBots(handleLoadingStates, fetchBody));
    } catch (e) {
      handleLoadingStates("createBot", false);
      console.log(e);
    }
  };

export const deleteBot =
  (
    id: string,
    handleLoadingStates: any,
    setIsDeleteBotModal: any,
    fetchBody: any
  ) =>
  async (dispatch) => {
    try {
      handleLoadingStates("deleteBot", true);
      const result = await botsApi.deleteBot(id);
      handleLoadingStates("deleteBot", false);
      if (isString(result)) {
        toast.success(result);
        setIsDeleteBotModal(false);
        dispatch(fetchAllBots(handleLoadingStates, fetchBody));
      }
    } catch (e) {
      console.log(e);
    }
  };

export const updateBot =
  (id: string, body: CreateBot, handleLoadingStates: any, fetchBody: any) =>
  async (dispatch) => {
    try {
      handleLoadingStates("updateBot", true);
      const result = await botsApi.updateBot(id, body);
      handleLoadingStates("updateBot", false);
      if (isString(result)) {
        toast.success(result);
        dispatch(fetchAllBots(handleLoadingStates, fetchBody));
      }
    } catch (e) {
      console.log(e);
    }
  };

export const publishBot =
  (id: string, handleLoadingStates: any, fetchBody: any) =>
  async (dispatch) => {
    try {
      const result = await botsApi.publishBot(id);
      if (isString(result)) {
        toast.success(result);
      }
      dispatch(fetchAllBots(handleLoadingStates, fetchBody));
    } catch (e) {
      console.log(e);
    }
  };

export const unpublishBot =
  (id: string, handleLoadingStates: any, fetchBody: any) =>
  async (dispatch) => {
    try {
      const result = await botsApi.unpublishBot(id);
      if (isString(result)) {
        toast.success(result);
      }
      dispatch(fetchAllBots(handleLoadingStates, fetchBody));
    } catch (e) {
      console.log(e);
    }
  };

export const fetchAllBotThemes =
  (handleLoadingStates: any) => async (dispatch) => {
    handleLoadingStates("fetchAllBotThemes", true);
    try {
      dispatch({ type: FETCH_ALL_BOT_THEMES_START });
      const result = await botsApi.getAllBotThemes();
      handleLoadingStates("fetchAllBotThemes", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_ALL_BOT_THEMES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_ALL_BOT_THEMES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (e) {
      handleLoadingStates("fetchAllBotThemes", false);
      console.log(e);
    }
  };

export const uploadImageFile =
  (body: any, handleLoadingStates: any, loadingState: any) => async () => {
    handleLoadingStates(loadingState, true);
    try {
      const { publicUrl } = await botsApi.uploadImageFile(body);
      handleLoadingStates(loadingState, false);
      if (publicUrl) return publicUrl;
    } catch (error) {
      console.log(error);
    }
  };
