import { styled } from "@mui/material/styles";
import { IconButton, Toolbar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";

import AccountPopover from "./AccountPopover";
import Credits from "./credits/Credits";

import useSettings from "src/hooks/useSettings";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  height: "66px",
  borderBottom: `1px solid ${
    theme.palette.mode === "dark"
      ? "rgb(255, 255, 255, 5%)"
      : "rgb(0, 0, 0, 10%)"
  }`,
  borderTop: "none",
  borderLeft: `1px solid ${
    theme.palette.mode === "dark"
      ? "rgb(255, 255, 255, 5%)"
      : "rgb(0, 0, 0, 10%)"
  }`,
  borderRight: "none",

  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  width: `calc(100% - 63px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DashboardNavbar = (props) => {
  const { settings } = useSettings();

  const { open, handleDrawer, totalCredits, availableFeatures } = props;
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            <MenuIcon
              htmlColor={settings?.theme === "DARK" ? "#ffffff" : "#0692e2"}
            />
          </IconButton>
        </Toolbar>
        <Toolbar sx={{ display: "flex", gap: 2 }}>
          <Credits
            totalCredits={totalCredits}
            availableFeatures={availableFeatures}
          />
          <AccountPopover />
        </Toolbar>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
