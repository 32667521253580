import axios from "axios";

import { authenticationConfig } from "src/config";
class EntityServiceAPI {
  async fetchUserProfile() {
    try {
      const { baseUrl } = authenticationConfig();
      let url = `${baseUrl}/users/me`;
      let response = await axios.get(url);
      if (response != null && response.data != null) {
        return response.data;
      } else {
      }

      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

}

export const entityServiceAPI = new EntityServiceAPI();
